<template>
  <div class="main">
    <!-- <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>组织架构</el-breadcrumb-item>
    </el-breadcrumb> -->
    <el-card
      shadow="hover"
      class="card-sty"
      body-style="height:100%;display: flex;flex-direction: column;justify-content: space-between;"
    >
      <el-table
        header-row-class-name="my-table-head-style"
        style="margin-top: 0; margin-bottom: 0; flex: 1"
        height="100%"
        :replaceFields="replaceFields"
        v-loading="spinning"
        :data="data"
        row-key="id"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <el-table-column prop="name" label="组织名称">
          <template slot-scope="scope">
            <span slot="reference" class="name-wrapper">
              <el-tag size="medium" type="info">{{ scope.row.name }}</el-tag>
            </span>
          </template>
        </el-table-column>

        <el-table-column
          width="350"
          label="操作"
          align="center"
          v-if="
            authVerify.verify(
              'organizationManagement-edit,organizationManagement-delete,organizationManagement-add'
            )
          "
        >
          <template slot-scope="scope">
            <a-tooltip
              placement="topLeft"
              title="新增子级部门"
              v-auth="'organizationManagement-add'"
            >
              <el-button
                icon="el-icon-circle-plus-outline"
                size="mini"
                @click="addOrganizationChild(scope.row)"
                >新增子部门
              </el-button>
            </a-tooltip>
            <el-button
              icon="el-icon-edit-outline"
              size="mini"
              v-auth="'organizationManagement-edit'"
              @click="editOrganization(scope.row)"
              >编辑
            </el-button>
            <el-button
              icon="el-icon-delete"
              size="mini"
              v-auth="'organizationManagement-delete'"
              @click="deleteOrganization(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <!-- 添加 -->
    <template>
      <div>
        <a-modal
          v-model="addOrganizationVisible"
          title="新增部门"
          width="600px"
        >
          <template slot="footer">
            <a-button @click="addOrganizationVisible = false">取消</a-button>
            <a-button type="primary" @click="toAddOrganization">
              确认
            </a-button>
          </template>
          <a-form-model
            :model="organizationFormValue"
            ref="organizationRefFormAdd"
            :rules="organizationRules"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 15 }"
          >
            <a-form-model-item label="部门名称" prop="name">
              <a-input v-model="organizationFormValue.name" placeholder="请输入部门名称"/>
            </a-form-model-item>
          </a-form-model>
        </a-modal>
      </div>
    </template>
    <!-- 编辑 -->
    <template>
      <div>
        <a-modal
          v-model="editOrganizationVisible"
          title="编辑部门"
          width="600px"
        >
          <template slot="footer">
            <a-button @click="editOrganizationVisible = false">取消</a-button>
            <a-button type="primary" @click="toEditOrganization">
              确认
            </a-button>
          </template>
          <a-form-model
            :model="organizationFormValue"
            ref="organizationRefFormEdit"
            :rules="organizationRules"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 15 }"
          >
            <a-form-model-item label="部门名称" prop="name">
              <a-input v-model="organizationFormValue.name" placeholder="请输入部门名称"/>
            </a-form-model-item>
          </a-form-model>
        </a-modal>
      </div>
    </template>

    <template>
      <div>
        <a-drawer
          title="搜索机构"
          width="520"
          placement="right"
          :closable="false"
          :visible="drawerVisible"
          :after-visible-change="afterVisibleChange"
          @close="onDrawerClose"
        >
          <div>
            <a-form-model
              :model="query"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 16 }"
            >
              <a-row>
                <a-col :span="12">
                  <a-form-model-item label="机构名称" prop="name">
                    <a-input v-model="query.name" allowClear placeholder="请输入机构名称"/>
                  </a-form-model-item>
                  <a-form-model-item label="机构地址" prop="formerName">
                    <a-input v-model="query.address" allowClear placeholder="请输入机构地址"/>
                  </a-form-model-item>
                </a-col>
                <a-col :span="12">
                  <a-form-model-item label="联系方式" prop="linkMan">
                    <a-input v-model="query.contact" allowClear placeholder="请输入联系方式"/>
                  </a-form-model-item>
                  <a-form-model-item label="机构描述" prop="phone">
                    <a-input v-model="query.introduction" allowClear placeholder="请输入机构描述"/>
                  </a-form-model-item>
                </a-col>
              </a-row>
            </a-form-model>
            <div>
              <a-button @click="removeAllQuery">清空</a-button>&nbsp;
              <a-button type="primary" @click="searchQuery">搜索</a-button>
            </div>
          </div>
        </a-drawer>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      replaceFields: { children: "children", title: "name", key: "code" },
      tree_change: true,
      areaData: [],
      onlyShowGuangDong: false,
      fieldName: { label: "name", value: "code", children: "children" },
      organizationFormValue: {
        id: "",
        name: "",
        address: "",
        areaIds: [],
        contact: "",
        introduction: "",
        parent: "",
        isTopOrganization: true,
      },
      organizationRules: {
        name: [{ required: true, message: "请输入机构名字!", trigger: "blur" }],
        address: [
          { required: true, message: "请输入机构地址!", trigger: "blur" },
        ],
        areaIds: [
          { required: true, message: "请选择机构区域!", trigger: "blur" },
        ],
      },
      query: {
        name: "",
        address: "",
        contact: "",
        introduction: "",
      },
      spinning: true,
      screenWidth: document.documentElement.clientWidth, //屏幕宽度
      screenHeight: document.documentElement.clientHeight - 50, //屏幕高度
      drawerVisible: false,
      titleData: [], //标题所有title的数据
      addOrganizationVisible: false,
      editOrganizationVisible: false,
      expandedKeys: [], //树key
      searchValue: "", //树搜索
      autoExpandParent: true, //树
      searchText: "", //搜索
      searchInput: null, //搜索
      areaClassifyData: [], //分类树数据
      data: [],
      treeSelectedKeys: [],
    };
  },
  watch: {
    screenWidth: function (val) {
      //监听屏幕宽度变化
      console.log(val);
    },
    screenHeight: function (val) {
      //监听屏幕高度变化
      console.log(val);
    },
    onlyShowGuangDong: function (val) {
      this.treeSelectedKeys = [];
      this.expandedKeys = [];
      this.tree_change = false;
      // 在组件移除后，重新渲染组件
      // this.$nextTick可实现在DOM 状态更新后，执行传入的方法。
      this.$nextTick(() => {
        this.tree_change = true;
      });
      this.getAreaClassifyData();
      this.spinning = true;
      this.getOrganizationData();
    },
  },
  mounted() {
    const _this = this;
    window.onresize = function () {
      // 定义窗口大小变更通知事件
      _this.screenWidth = document.documentElement.clientWidth; //窗口宽度
      _this.screenHeight = document.documentElement.clientHeight - 50; //窗口高度
    };
  },
  created() {
    this.getAreaClassifyData();
    this.getOrganizationData();
    this.getAreaData();
  },
  computed: {
    pageHeight: function () {
      let height = document.documentElement.clientHeight;
      return height - 50;
    },
  },
  methods: {
    showDrawer() {
      this.drawerVisible = true;
    },
    onDrawerClose() {
      this.drawerVisible = false;
    },
    afterVisibleChange() {},
    areaChange(value, selectedOptions) {
      console.log(value, selectedOptions);
    },
    areaFilter(inputValue, path) {
      return path.some(
        (option) =>
          option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
      );
    },
    toAddOrganization() {
      this.$refs.organizationRefFormAdd.validate((valid) => {
        if (valid) {
          this.http.organization
            .createOrganization(this.organizationFormValue)
            .then((response) => {
              if (response.data.status === 200) {
                this.$message.success(response.data.msg);
                this.addOrganizationVisible = false;
              } else {
                this.$message.warning(response.data.msg);
              }
              this.getOrganizationData();
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    deleteOrganization(key) {
      let self = this;
      this.$confirm({
        title: "确定删除吗?",
        content: "",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          this.http.organization
            .deleteOrganization({ id: key.id })
            .then((response) => {
              if (response.data.status === 200) {
                this.$message.success(response.data.msg);
                self.getOrganizationData();
              } else {
                this.$message.error(response.data.msg);
              }
            });
        },
        onCancel() {},
      });
    },

    //树   展开节点的时候触发
    onExpand(expandedKeys) {
      console.log(expandedKeys);
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    //当点击选择的时候
    onSelect(selectedKeys) {
      console.log(selectedKeys);
      this.treeSelectedKeys = selectedKeys;
      this.getOrganizationData();
    },
    onChange(e) {
      console.log("搜索中");
      const value = e.target.value;
      if (value === "") {
        this.expandedKeys = [];
        this.searchValue = "";
      } else {
        const expandedKeys = this.titleData
          .map((item) => {
            if (item.title.indexOf(value) > -1) {
              return this.getParentKey(item.title, this.areaClassifyData);
            }
            return null;
          })
          .filter((item, i, self) => item && self.indexOf(item) === i);
        Object.assign(this, {
          expandedKeys,
          searchValue: value,
          autoExpandParent: true,
        });
      }
    },
    //树 获取父id 去展开
    getParentKey(title, tree) {
      let parentKey;
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.children) {
          if (node.children.some((item) => item.title === title)) {
            parentKey = node.code;
          } else if (this.getParentKey(title, node.children)) {
            parentKey = this.getParentKey(title, node.children);
          }
        }
      }
      return parentKey;
    },
    //获取树的所有节点值
    getTitleData(data) {
      for (let i = 0; i < data.length; i++) {
        const node = data[i];
        const title = node.title;
        this.titleData.push({
          title: title,
        });
        if (node.children) {
          this.getTitleData(node.children);
        }
      }
    },
    getAreaData() {
      this.http.area
        .getAreaListHasId({
          onlyShowGuangDong: this.onlyShowGuangDong,
        })
        .then((res) => {
          if (res.data.status === 200) {
            this.areaData = res.data.data;
          } else {
            this.$message.warning("数据获取失败");
          }
        });
    },
    getAreaClassifyData() {
      let params = {
        onlyShowGuangDong: this.onlyShowGuangDong,
      };
      this.http.organization.getOrganizationTreeByArea(params).then((res) => {
        if (res.data.status === 200) {
          this.areaClassifyData = res.data.data;
          this.getTitleData(res.data.data);
        } else {
          this.$message.warning("数据获取失败");
        }
      });
    },
    editOrganization(record) {
      this.cleanOrganization();
      this.organizationFormValue = {
        id: record.id,
        name: record.name,
        address: record.address,
        areaIds: record.areaIds,
        contact: record.contact,
        introduction: record.introduction,
        isTopOrganization: record.isTopOrganization,
      };
      this.editOrganizationVisible = true;
        this.$refs.organizationRefFormEdit.resetFields()
    },
    addOrganization() {
      this.cleanOrganization();
      this.addOrganizationVisible = true;
        this.$refs.organizationRefFormAdd.resetFields()
    },
    addOrganizationChild(obj) {
      this.cleanOrganization();
      this.organizationFormValue.isTopOrganization = false;
      this.organizationFormValue.parent = obj.id;
      this.addOrganizationVisible = true;
        this.$refs.organizationRefFormAdd.resetFields()
    },
    cleanOrganization() {
      this.organizationFormValue = {
        id: "",
        name: "",
        address: "",
        areaIds: [],
        contact: "",
        introduction: "",
        parent: "",
        isTopOrganization: true,
      };
    },
    getOrganizationData() {
      let params = {
        areaId: this.treeSelectedKeys.toString(),
        name: this.query.name,
        address: this.query.address,
        contact: this.query.contact,
        introduction: this.query.introduction,
      };
      // pagesize current 搜索的数据  旁边树的id
      this.http.organization.getOrganizationOneselfList(params).then((res) => {
        if (res.data.status == 200) {
          this.spinning = false;
          this.data = res.data.data;
        } else {
          this.$message.warning("数据获取失败");
        }
      });
    },

    toEditOrganization() {
      this.$refs.organizationRefFormEdit.validate((valid) => {
        if (valid) {
          this.http.organization
            .updateOrganization(this.organizationFormValue)
            .then((response) => {
              if (response.data.status === 200) {
                this.$message.success(response.data.msg);
                this.editOrganizationVisible = false;
              } else {
                this.$message.warning(response.data.msg);
              }
              this.getOrganizationData();
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    removeAllQuery() {
      this.query = {
        coding: "",
        name: "",
        formerName: "",
        linkMan: "",
        phone: "",
        address: "",
      };
      this.searchQuery();
    },
    searchQuery() {
      this.spinning = true;
      this.getOrganizationData();
      this.drawerVisible = false;
    },
  },
};
</script>

<style scoped>
/*滚动条整体样式*/
.scroll_bar::-webkit-scrollbar {
  width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
}

.scroll_bar::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background-color: #cecece;
}

.scroll_bar::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #ededed;
}
</style>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.main {
  padding: 14px;
  height: 100%;
  .card-sty {
    height: 100%;
  }
}
</style>
